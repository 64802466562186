import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-magic-items"></a><h2>Epic Magic Items</h2>
    <a id="intelligent-items"></a><h3>INTELLIGENT ITEMS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Just like nonepic magic items, epic magic items sometimes possess
intelligence of their own. Such items are fully sentient and should be
treated as NPCs. The Random Epic Magic Items section details the
chances that epic armor, shields, rings, rods, staffs, wondrous items,
and weapons might be intelligent. In short, a ring, rod, staff,
wondrous item, armor, or shield has a 1% chance to be intelligent, a
ranged weapon has a 5% chance to be intelligent, and a melee weapon has
a 15% chance to be intelligent. Rather than using the tables for
nonepic items, use the tables below to determine the properties of an
intelligent item: the number of powers, unusual properties, alignment,
and special purpose of the item (if any). Of the three mental ability
scores, two scores are favored (2d6 + some number) and one is
completely random (3d6). Choose which scores get assigned which number,
or roll 1d4 and determine randomly according to the following table. 
    <a id="table-mental-ability-scores-for-intelligent-items"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Mental Ability Scores for
Intelligent Items </span>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>1d4
          </th>
          <th style={{
            "width": "30%"
          }}>High Score
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "30%"
          }}><span style={{
              "fontWeight": "bold"
            }}>Medium Score</span>
          </td>
          <th style={{
            "width": "30%"
          }}>Low Score
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>1
          </td>
          <td style={{
            "width": "30%"
          }}>Intelligence
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "30%"
          }}>Charisma</td>
          <td style={{
            "width": "30%"
          }}>Wisdom</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>2
          </td>
          <td style={{
            "width": "30%"
          }}>Intelligence</td>
          <td style={{
            "verticalAlign": "top",
            "width": "30%"
          }}>Wisdom</td>
          <td style={{
            "width": "30%"
          }}>Charisma</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>3
          </td>
          <td style={{
            "width": "30%"
          }}>Wisdom
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "30%"
          }}>Intelligence</td>
          <td style={{
            "width": "30%"
          }}>Charisma
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>4
          </td>
          <td style={{
            "width": "30%"
          }}>Charisma</td>
          <td style={{
            "verticalAlign": "top",
            "width": "30%"
          }}>Intelligence</td>
          <td style={{
            "width": "30%"
          }}>Wisdom</td>
        </tr>
      </tbody>
    </table>
    <p>
      <p>{`The first step in determining the properties of a random intelligent
epic magic item is to determine its general capabilities. These are
found by rolling d% and consulting Table: Epic Items Intelligence,
Wisdom, Charisma, and Capabilities.`}</p>
    </p>
    <a id="table-epic-item-intelligence-wisdom-charisma-and-capabilities"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: Epic Item
Intelligence, Wisdom, Charisma, and Capabilities</span></p>
    <table style={{
      "width": "650px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "5%"
          }}>d%
          </th>
          <th style={{
            "width": "20%"
          }}>Ability Scores
          </th>
          <th style={{
            "width": "60%"
          }}>Capabilities</th>
        </tr>
        <tr className="odd-row">
          <td>01-22
          </td>
          <td>Two 2d6+10, one 3d6 
          </td>
          <td>Three primary abilities, one extraordinary power 
          </td>
        </tr>
        <tr>
          <td>23-40
          </td>
          <td>Two 2d6+11, one 3d6 
          </td>
          <td>Three primary abilities, two extraordinary powers
          </td>
        </tr>
        <tr className="odd-row">
          <td>41-54
          </td>
          <td>Two 2d6+12, one 3d6 
          </td>
          <td>Four primary abilities, two extraordinary powers
          </td>
        </tr>
        <tr>
          <td>55-64
          </td>
          <td>Two 2d6+14, one 3d6 
          </td>
          <td>Four primary abilities, three extraordinary powers</td>
        </tr>
        <tr className="odd-row">
          <td>64-71
          </td>
          <td>Two 2d6+16, one 3d6 
          </td>
          <td>Four primary abilities, three extraordinary powers, one
awesome power
          </td>
        </tr>
        <tr>
          <td>72-73
          </td>
          <td>Two 2d6+18, one 3d6 
          </td>
          <td>Four primary abilities, three extraordinary powers, two
awesome powers</td>
        </tr>
        <tr className="odd-row">
          <td>74
          </td>
          <td rowSpan="1" colSpan="2">Roll again, but add 1d6 to each
ability score<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td>75-100
          </td>
          <td rowSpan="1" colSpan="2">Use nonepic magic item Table:
Item Intelligence, Wisdom, Charisma, and Capabilities
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="3">1 <span style={{
              "fontStyle": "italic"
            }}>Cumulative if rolled multiple times.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <p> </p>
    <a id="market-price-modifier"></a><h5>MARKET PRICE MODIFIER </h5>
To find the market price for an epic magic item, use these expanded
guidelines, adjusting as necessary to find an appropriate final price.
Each point of Intelligence bonus, Wisdom bonus, or Charisma bonus
increases the item&#8217;s market price by 400 gp. Any form of communication
possessed by the item increases its market price by the number shown on
Table: Epic Item Communication. Each primary ability possessed by the
item increases its market price by 2,000 to 10,000 gp (average 6,000
gp). Each extraordinary ability possessed by the item increases its
market price by 15,000 to 35,000 gp (average 25,000 gp). A special
purpose increases the item&#8217;s market price by 50,000 gp. An awesome
power increases the item&#8217;s market price by 100,000 gp. 
    <a id="intelligent-item-alignment"></a><h5>INTELLIGENT ITEM ALIGNMENT </h5>
Any item with Intelligence has an alignment. Make sure that the
alignment choosen or determine randomly (using Table: Item Alignment)
matches any alignment-oriented special abilities of the item. Any
character whose alignment is not compatible with that of the item gains
one negative level for every 10 points of the weapon&#8217;s Ego (see Item
Ego, below) if he or she so much as picks up the item. Although this
never results in actual level loss, the negative levels remain as long
as the item is in hand and cannot be overcome in any way (including
restoration spells). These negative levels are cumulative with any
other penalties the item might already place on inappropriate wielders.
    <a id="table-item-alignment"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Item Alignment </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>d%</th>
          <th>Alignment of Item</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;05</td>
          <td>Chaotic good</td>
        </tr>
        <tr>
          <td>06&#8211;15</td>
          <td>Chaotic neutral<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>16&#8211;20</td>
          <td>Chaotic evil</td>
        </tr>
        <tr>
          <td>21&#8211;25</td>
          <td>Neutral evil<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>26&#8211;30</td>
          <td>Lawful evil</td>
        </tr>
        <tr>
          <td>31&#8211;55</td>
          <td>Lawful good</td>
        </tr>
        <tr className="odd-row">
          <td>56&#8211;60</td>
          <td>Lawful neutral<sup>1</sup></td>
        </tr>
        <tr>
          <td>61&#8211;80</td>
          <td>Neutral good<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;100</td>
          <td>Neutral</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>The item can also be used by any
character whose alignment corresponds to the nonneutral portion of the
item&#8217;s alignment.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="item-communication"></a><h5>ITEM COMMUNICATION </h5>
Like a character, an intelligent item speaks Common plus one language
per point of Intelligence bonus. Choose appropriate languages, taking
into account the item&#8217;s origin and purposes. An item with multiple
modes of communication can use any of its modes at will. 
    <a id="table-epic-item-communication"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Epic Item Communication </span>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "5%"
          }}>d%
          </th>
          <th style={{
            "width": "20%"
          }}>Communication Mode
          </th>
          <th style={{
            "width": "40%"
          }}>Market Price Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>01-10
          </td>
          <td>Semiempathy<sup>1</sup>
          </td>
          <td style={{
            "width": "40%"
          }}>+1,000 gp
          </td>
        </tr>
        <tr>
          <td>11-35
          </td>
          <td>Empathy<sup>2</sup>
          </td>
          <td style={{
            "width": "40%"
          }}>+2,000 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>36-75
          </td>
          <td>Speech<sup>3</sup>
          </td>
          <td style={{
            "width": "40%"
          }}>+3,000 gp
          </td>
        </tr>
        <tr>
          <td>76-85
          </td>
          <td>Telepathy<sup>4</sup>
          </td>
          <td style={{
            "width": "40%"
          }}>+5,000 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>86-100
          </td>
          <td>Speech<sup>3</sup> and telepathy<sup>4</sup>
          </td>
          <td style={{
            "width": "40%"
          }}>+8,000 gp
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="3">1 <span style={{
              "fontStyle": "italic"
            }}>The possessor receives some signal (a
throb or tingle, for example) when the item&#8217;s ability functions.</span>
&nbsp;&nbsp;&nbsp; 
            <p>{`2 `}<span style={{
                "fontStyle": "italic"
              }}>{`The possessor feels urges and
sometimes emotions from the item that encourage or discourage certain
courses of action.`}</span>{` `}{` `}{` `}{` `}{` `}</p>
            <p>{`3 `}<span style={{
                "fontStyle": "italic"
              }}>{`Speaks Common plus one language
per point of Intelligence bonus. Roll d%: 01-05, the item cannot read
any languages; 06-75, the item can read any language it can speak
(+1,000 gp to market price); 76-90, it can read all languages (+2,000
gp to market price); 91-100, it can read all languages as well as read
magic (+3,000 gp to market price). `}</span>{` `}{` `}{` `}{` `}</p>
            <p>{`4 `}<span style={{
                "fontStyle": "italic"
              }}>{`The item can communicate silently
with any wielder who has an Intelligence of 1 or higher, regardless of
any language barrier.`}</span>{` `}{` `}{` `}{` `}{` `}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="intelligent-item-abilities"></a><span style={{
      "fontWeight": "bold"
    }}>INTELLIGENT ITEM ABILITIES </span>
    <p>{`Using the number of capabilities determined above, find the item`}{`’`}{`s
specific abilities by rolling on the appropriate tables below. `}</p>
    <a id="table-intelligent-item-primary-abilities"></a><table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%
          </th>
          <th style={{
            "width": "90%"
          }}>Primary Ability
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-04
          </td>
          <td style={{
            "width": "90%"
          }}>Item has 10 ranks in Intuit
Direction &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; 
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>05-08
          </td>
          <td style={{
            "width": "90%"
          }}>Item has 10 ranks in Sense Motive</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>09-12
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Combat
Reflexes</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>13-16
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Blind-Fight</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>17-20
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Improved
Initiative</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>21-24
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Mobility</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>25-28
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Sunder</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>29-32
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of Expertise</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>33-39
          </td>
          <td style={{
            "width": "90%"
          }}>Detect [opposing alignment] at will</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>40-42
          </td>
          <td style={{
            "width": "90%"
          }}>Find traps at will</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>43-47
          </td>
          <td style={{
            "width": "90%"
          }}>Detect secret doors at will</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>48-54
          </td>
          <td style={{
            "width": "90%"
          }}>Detect magic at will</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>55-57
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of uncanny
dodge (as a 5th-level barbarian)</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>58-60
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder has free use of evasion</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>61-65
          </td>
          <td style={{
            "width": "90%"
          }}>Wielder can use see invisibility at
will</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>66-70
          </td>
          <td style={{
            "width": "90%"
          }}>Cure light wounds (1d8+5) on
wielder 1/day</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>71-75
          </td>
          <td style={{
            "width": "90%"
          }}>Feather fall on wielder 1/day</td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "width": "10%"
          }}>76
          </td>
          <td className="last-row" style={{
            "width": "90%"
          }}>Locate object in a
120-ft. radius</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "10%"
          }}>77
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "90%"
          }}>Wielder
does not need to sleep</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "width": "10%"
          }}>78
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "90%"
          }}>Wielder does
not need to breathe</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 239, 188)"
          }}>79
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 239, 188)"
          }}>Jump
for 20 minutes on wielder 1/day</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>80
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Spider climb for 20
minutes on wielder 1/day</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>81-90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Roll
twice again on this table</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>91-100
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Roll on Table: Intelligent
Item Extraordinary Powers instead</td>
        </tr>
      </tbody>
    </table>
    <p>If the same ability is rolled twice or more, the range,
frequency, or effectiveness of the power is doubled, tripled, and so
on. All abilities function only when the item is held, drawn, or
otherwise brandished and the possessor is concentrating on the desired
result. Activating a power is a standard action, but using a free feat
is not. Feats may be used regardless of prerequisites, but the item
still must be held and drawn (or worn, in the case of such items). An
intelligent item might activate a power on its own.
    </p>
    <a id="table-intelligent-item-extraordinary-powers"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: Intelligent Item
Extraordinary Powers </span>
    </p>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%
          </th>
          <th style={{
            "width": "80%"
          }}>Extraordinary Power
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Uses</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-05
          </td>
          <td style={{
            "width": "80%"
          }}>Charm person (DC 11 <sup>1</sup>)
on contact
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>06-10
          </td>
          <td style={{
            "width": "80%"
          }}>Clairaudience/clairvoyance (100-ft.
range, 1 minute per use) </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>11-15
          </td>
          <td style={{
            "width": "80%"
          }}>Magic missile (200-ft. range, 3
missiles) </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>16-20
          </td>
          <td style={{
            "width": "80%"
          }}>Shield on wielder </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>21-25
          </td>
          <td style={{
            "width": "80%"
          }}>Detect thoughts (100-ft. range, 1
minute per use) </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>26-30
          </td>
          <td style={{
            "width": "80%"
          }}>Levitation (wielder only, 10 minute
duration) </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>31-35
          </td>
          <td style={{
            "width": "80%"
          }}>Invisibility (wielder only, up to
30 minutes per use) </td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>36-40
          </td>
          <td style={{
            "width": "80%"
          }}>Fly (30 minutes per use) </td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>41-45
          </td>
          <td style={{
            "width": "80%"
          }}>Lightning bolt (8d6 points of
damage, 200-ft. range, DC 13<sup>1</sup>) </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>46-50
          </td>
          <td style={{
            "width": "80%"
          }}>Summon monster III </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>51-55
          </td>
          <td style={{
            "width": "80%"
          }}>Telepathy (100 ft. range) </td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>56-60
          </td>
          <td style={{
            "width": "80%"
          }}>Cat&#8217;s grace (wielder only) </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>61-65
          </td>
          <td style={{
            "width": "80%"
          }}>Bull&#8217;s strength (wielder only) </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>66-70
          </td>
          <td style={{
            "width": "80%"
          }}>Haste (wielder only, 10 rounds) </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>71-73
          </td>
          <td style={{
            "width": "80%"
          }}>Telekinesis (250 lb. maximum, 1
minute each use) </td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>74-76
          </td>
          <td style={{
            "width": "80%"
          }}>Heal </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>77
          </td>
          <td style={{
            "width": "80%"
          }}>Teleport, 600 lb. maximum </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "width": "10%"
          }}>78
          </td>
          <td className="last-row" style={{
            "width": "80%"
          }}>Globe of
invulnerability </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "10%"
          }}>70
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>Stoneskin
(wielder only, 10 minutes per use) </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "width": "10%"
          }}>80
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "80%"
          }}>Feeblemind by
touch </td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 239, 188)"
          }}>81
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>True
seeing </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>At
will
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>82
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "80%"
          }}>Wall of force </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>83
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>Summon
monster VI </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>84
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "80%"
          }}>Finger of
death (100 ft. range, DC 17<sup>1</sup>) </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>85
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>Passwall</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>At
will
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>86-90
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "80%"
          }}>Roll twice
again on this table</td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>91-100
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>Roll
again on this table, and then roll for a special purpose on Table:
Intelligent Item Purpose</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>-
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="3">1 <span style={{
              "fontStyle": "italic"
            }}>Choose an ability score of the item
(usually the highest) at the time the item is created or randomly
generated. Add that ability&#8217;s bonus to the given DC.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <p> </p>
    <p>If the same power is rolled twice, the uses per day are
doubled. (If true seeing or passwall is rolled twice, roll again.)
Powers function only when the item is drawn and held, and the possessor
is concentrating upon the desired effect. Activating a power is a
standard action. An intelligent item might activate a power on its own.
    </p>
    <a id="table-intelligent-item-awesome-powers"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Intelligent Item Awesome
Powers
    </span>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%
          </th>
          <th style={{
            "width": "80%"
          }}>Awesome Power
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Uses</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-04
          </td>
          <td style={{
            "width": "80%"
          }}>Astral projection
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>05-08
          </td>
          <td style={{
            "width": "80%"
          }}>Bull&#8217;s strength (wielder only;
intensified; +10 enhancement bonus to Strength)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>09-12
          </td>
          <td style={{
            "width": "80%"
          }}>Cat&#8217;s grace (wielder only;
intensified; +10 enhancement bonus to Dexterity)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>13-16
          </td>
          <td style={{
            "width": "80%"
          }}>Chain lightning (enhanced; 20d6
damage; DC 16<sup>1</sup>)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>17-20
          </td>
          <td style={{
            "width": "80%"
          }}>Dominate monster (DC 19<sup>1</sup>)
on contact</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>21-24
          </td>
          <td style={{
            "width": "80%"
          }}>Endurance (wielder only;
intensified; +10 enhancement bonus to Constitution)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>25-28
          </td>
          <td style={{
            "width": "80%"
          }}>Energy drain (DC 19<sup>1</sup>) on
contact</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>29-32
          </td>
          <td style={{
            "width": "80%"
          }}>Finger of death (heightened to 9th
level; DC 19<sup>1</sup>)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>33-36
          </td>
          <td style={{
            "width": "80%"
          }}>Foresight (wielder only)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>37-40
          </td>
          <td style={{
            "width": "80%"
          }}>Gate</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>41-44
          </td>
          <td style={{
            "width": "80%"
          }}>Haste (wielder only; extended;
40-round duration)</td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>45-48
          </td>
          <td style={{
            "width": "80%"
          }}>Improved invisibility (wielder
only; extended; 40-minute duration)</td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>49-52
          </td>
          <td style={{
            "width": "80%"
          }}>Mass heal 1/day 53-56 Meteor swarm
(DC 19<sup>1</sup>)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>53-60
          </td>
          <td style={{
            "width": "80%"
          }}>Phase door 2/day 61-64 Prismatic
sphere (DC 19<sup>1</sup>)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>65-68
          </td>
          <td style={{
            "width": "80%"
          }}>Stoneskin (wielder only; extended;
400-minute duration)</td>
          <td style={{
            "verticalAlign": "top"
          }}>3/day
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>69-72
          </td>
          <td style={{
            "width": "80%"
          }}>Summon monster IX<sup>2</sup>
(extended; 40-round duration)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>73-76
          </td>
          <td style={{
            "width": "80%"
          }}>Sunburst (heightened to 9th level;
DC 19<sup>1</sup>)</td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "width": "10%"
          }}>77-80
          </td>
          <td className="last-row" style={{
            "width": "80%"
          }}>Teleport without
error</td>
          <td style={{
            "verticalAlign": "top"
          }}>2/day
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "10%"
          }}>81-90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "80%"
          }}>Roll
twice again on this table</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>-
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "width": "10%"
          }}>91-100
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "80%"
          }}>Roll again on
this table, and then roll for a special purpose on nonepic magic item
Table: Intelligent Item Purpose.</td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="3">1 <span style={{
              "fontStyle": "italic"
            }}>Choose an ability score of the item
(usually the highest) at the time the item is created or randomly
generated. Add that ability&#8217;s bonus to the listed DC.</span>
&nbsp;&nbsp;&nbsp; 
            <p>{`2 `}<span style={{
                "fontStyle": "italic"
              }}>{`The weapon can only summon
monsters whose alignment has no components that oppose the item`}{`’`}{`s
alignment. `}</span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>{`Unless otherwise stated, all awesome powers function at 20th caster
level. If a power is rolled twice, the uses per day are doubled. Powers
function only when the item is drawn and held, and the possessor is
concentrating upon the desired effect. Activating a power is a standard
action. An intelligent item might activate a power on its own. `}</p>
    <a id="special-purpose-items"></a><h5>SPECIAL PURPOSE ITEMS </h5>
    <p><span style={{
        "fontWeight": "bold"
      }}>Purpose</span>
      <p>{`An item`}{`’`}{`s purpose must suit the type and alignment of the item and
should always be treated reasonably.`}</p>
    </p>
    <a id="table-intelligent-item-purpose"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Intelligent Item Purpose </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Purpose</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;20</td>
          <td>Defeat/slay diametrically opposed alignment<sup>1</sup></td>
        </tr>
        <tr>
          <td>21&#8211;30</td>
          <td>Defeat/slay arcane spellcasters (including spellcasting
monsters and those that use spell-like abilities)</td>
        </tr>
        <tr className="odd-row">
          <td>31&#8211;40</td>
          <td>Defeat/slay divine spellcasters (including divine
entities and servitors)</td>
        </tr>
        <tr>
          <td>41&#8211;50</td>
          <td>Defeat/slay nonspellcasters</td>
        </tr>
        <tr className="odd-row">
          <td>51&#8211;55</td>
          <td>Defeat/slay a particular creature type (see the bane
special ability for choices)</td>
        </tr>
        <tr>
          <td>56&#8211;60</td>
          <td>Defeat/slay a particular race or kind of creature</td>
        </tr>
        <tr className="odd-row">
          <td>61&#8211;70</td>
          <td>Defend a particular race or kind of creature</td>
        </tr>
        <tr>
          <td>71&#8211;80</td>
          <td>Defeat/slay the servants of a specific deity</td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;90</td>
          <td>Defend the servants and interests of a specific deity</td>
        </tr>
        <tr>
          <td>91&#8211;95</td>
          <td>Defeat/slay all (other than the item and the wielder)</td>
        </tr>
        <tr className="odd-row">
          <td>96&#8211;100</td>
          <td>Choose one</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>The purpose of the neutral (N)
version of this
item is to preserve the balance by defeating/slaying powerful beings of
the extreme alignments (LG, LE, CG, CE).</i></td>
        </tr>
      </tbody>
    </table>
    <a id="special-purpose-power"></a><h5>SPECIAL PURPOSE POWER </h5>
A special purpose power operates only when the item is in pursuit of
its special purpose. This is always up to the purview of the item. It
should always be easy and straightforward to see how the ends justify
the means. That is to say that if the player&#8217;s reasoning for how a
particular action serves the item&#8217;s purpose is not completely
believable, the item won&#8217;t allow it. 
    <a id="table-intelligent-item-special-purpose-powers"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Intelligent Item Special
Purpose Powers </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Special Purpose Power
          </th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;10</td>
          <td>Blindness<sup>1</sup> (DC 17<sup>2</sup>) for 2d6
rounds 
          </td>
        </tr>
        <tr>
          <td>11-20
          </td>
          <td>Confusion<sup>1</sup> (DC 19<sup>2</sup>) for 2d6 rounds</td>
        </tr>
        <tr className="odd-row">
          <td>21-25</td>
          <td>Fear<sup>1</sup> (DC 19<sup>2</sup>) for 1d4 rounds</td>
        </tr>
        <tr>
          <td>26-55</td>
          <td>Hold monster<sup>1</sup> (DC 19<sup>2</sup>) for 1d4
rounds</td>
        </tr>
        <tr className="odd-row">
          <td>56-65</td>
          <td>Slay living<sup>1</sup> (DC 20<sup>2</sup>)</td>
        </tr>
        <tr>
          <td>66-75</td>
          <td>Disintegrate<sup>1</sup> (DC 21<sup>2</sup>)</td>
        </tr>
        <tr className="odd-row">
          <td>76-80</td>
          <td>True resurrection on wielder, one time only</td>
        </tr>
        <tr>
          <td>81-100</td>
          <td>+4 luck bonus on all saving throws, +4 deflection AC
bonus, spell resistance 30</td>
        </tr>
        <tr>
          <td colSpan="2">1 <span style={{
              "fontStyle": "italic"
            }}>This
power affects the opponent of the item&#8217;s wielder on a successful hit
unless the opponent makes a Will save at the listed DC.</span>
&nbsp;&nbsp;&nbsp; 
            <p>{`2 `}<span style={{
                "fontStyle": "italic"
              }}>{`Choose an ability score of the
item (usually the highest) at the time the item is created or randomly
generated. Add that ability`}{`’`}{`s bonus to the listed DC. `}</span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="item-ego"></a><h5>ITEM EGO </h5>
Ego is a measure of the total power and force of personality that an
item possesses. Only after all aspects of an item have been generated
and recorded can its Ego score be determined. Ego is a factor with
regard to the dominance of item over character, as detailed below. 
    <a id="table-item-ego"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Item Ego </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "80%"
          }}>Attribute of Item</th>
          <th>Ego Points</th>
        </tr>
        <tr className="odd-row">
          <td>Each +1 enhancement of item up to +5
          </td>
          <td>1
          </td>
        </tr>
        <tr>
          <td>Each +1 enhancement of item above +5</td>
          <td>2
          </td>
        </tr>
        <tr className="odd-row">
          <td>Each +1 bonus of special abilities</td>
          <td>1
          </td>
        </tr>
        <tr>
          <td>Each primary ability<sup>1</sup></td>
          <td>1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Each extraordinary power<sup>1</sup></td>
          <td>2
          </td>
        </tr>
        <tr>
          <td>Each awesome power<sup>1</sup></td>
          <td>6
          </td>
        </tr>
        <tr className="odd-row">
          <td>Special purpose</td>
          <td>4
          </td>
        </tr>
        <tr>
          <td>Telepathic ability</td>
          <td>1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Read languages ability</td>
          <td>1
          </td>
        </tr>
        <tr>
          <td>Read magic ability</td>
          <td>1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Each +1 of Intelligence bonus</td>
          <td>1
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Each +1 of Wisdom bonus</td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Each
+1 of Charisma bonus</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="2">1 <span style={{
              "fontStyle": "italic"
            }}>If uses per day are doubled, Ego points
are doubled as well. </span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="items-against-characters"></a><h5>ITEMS AGAINST CHARACTERS </h5>
    <p>When an item has an Ego of its own, it has a will of its own.
The item is, of course, absolutely true to its alignment. If the
character who possesses the item is not true to that alignment&#8217;s goals
or the item&#8217;s special purpose, personality conflict-item against
character-results. Similarly, any item with an Ego score of 20 or
higher always considers itself superior to any character, and a
personality conflict results if the possessor does not always agree
with the item. When a personality conflict occurs, the possessor must
make a Will saving throw (DC = item&#8217;s Ego). If the possessor succeeds,
he or she is dominant. If he or she fails, the item is dominant.
Dominance lasts for one day or until a critical situation occurs (such
as a major battle, a serious threat to either item or character, and so
on). Should an item gain dominance, it resists the character&#8217;s desires
and demands concessions such as any of the following: </p>
    <ul>
      <li>
        <p>Removal of associates or items whose alignment or
personality is distasteful to the item.</p>
      </li>
      <li>
        <p>The character divesting him or herself of all other magic
items or items of a certain type.</p>
      </li>
      <li>
        <p>Obedience from the character so the item can direct where
they go for its own purposes.</p>
      </li>
      <li>
        <p>Immediate seeking out and slaying of creatures hateful to
the item.</p>
      </li>
      <li>
        <p>Magical protections and devices to protect the item from
molestation when it is not in use.</p>
      </li>
      <li>
        <p>That the character carry the item with him or her on all
occasions.</p>
      </li>
      <li>
        <p>That the character relinquish the item in favor of a more
suitable possessor due to alignment differences or conduct. </p>
      </li>
    </ul>
    <p> </p>
    <p>In extreme circumstances, the item can resort to even harsher
measures. </p>
    <ul>
      <li>
        <p>Force its possessor into combat.</p>
      </li>
      <li>
        <p>Refuse to strike opponents.</p>
      </li>
      <li>
        <p>Strike at its wielder or his or her associates.</p>
      </li>
      <li>
        <p>Force its possessor to surrender to an opponent.</p>
      </li>
      <li>
        <p>Cause itself to drop from the character&#8217;s grasp. </p>
      </li>
    </ul>
    <p> </p>
    <p>Naturally, such actions are unlikely when harmony reigns
between the character&#8217;s and item&#8217;s alignments or when their purposes
and personalities are well matched. Even so, an item might wish to have
a lesser character possess it in order to easily command him or her, or
a higher-level possessor so as to better accomplish its goals. All
magic items with personalities desire to play an important role in
whatever activity is under way, particularly combat. Such items are
rivals of each other, even if they are of the same alignment. No
intelligent item wants to share its wielder with others. An intelligent
item is aware of the presence of any other intelligent item within 60
feet, and most intelligent items try their best to mislead or distract
their host so that he or she ignores or destroys the rival. Of course,
alignment might change this sort of behavior. </p>
    <p>Items with personalities are never totally controlled or
silenced by the characters who possess them, even though they may never
successfully control their possessor. They may be powerless to force
their demands but remain undaunted and continue to air their wishes and
demands. </p>
    <a id="artifacts"></a><h3>ARTIFACTS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="minor-artifacts"></a><h4>MINOR ARTIFACTS </h4>
Minor artifacts are not necessarily unique items, but rather magic
items that no longer can be made by common mortal means-even by the
hands of epic creators. Such items have no established market price.
Below is a selection of known minor artifacts. 
    <a id="everfull-purse"></a><p><span style={{
        "fontWeight": "bold"
      }}>Everfull Purse:</span> This
leather belt pouch has the power to turn a single gold coin into many
overnight. If a single gold piece is placed in the everfull purse at
sunset, it will be replaced at sunrise by 25 gold pieces. The purse has
no effect if more than one gold piece is left within, or if anything
other than gold is placed within.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1/2 lb. </p>
    <a id="libram-of-gainful-conjuration"></a><p><span style={{
        "fontWeight": "bold"
      }}>Libram of Gainful Conjuration:</span>
This mystic book is of great value to arcane spellcasters of good
alignment (LG, NG, CG). Study of the work requires one week. Upon
completion, the good arcane caster gains a +1 inherent bonus to the
ability score controlling his or her arcane spellcasting ability and
experience points sufficient to place him or her halfway into the next
level of experience. (If the reader has levels in more than one arcane
spellcasting class, he or she must choose one of the classes to be
affected.) Nongood arcane spellcasters (LN, N, CN, LE, NE, or CE) are
permanently drained of 1d4+1 points of Constitution and must atone (see
the atonement spell) in order to gain any further experience. Anyone
incapable of casting arcane spells who reads even a single word of the
work must make a Will save (DC 20) or suffer insanity (see the insanity
spell). Except as indicated above, the writing in a libram of gainful
conjuration can&#8217;t be distinguished from any other book, libram, tome,
or so on until perused. Once read, the book vanishes, never to be seen
again, nor can the same character ever benefit from reading a similar
tome a second time.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 3 lb. </p>
    <a id="libram-of-ineffable-damnation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Libram of Ineffable Damnation:</span>
This mystic book is of great value to arcane spellcasters of evil
alignment (LE, NE, CE). Study of the work requires one week. Upon
completion, the evil arcane caster gains a +1 inherent bonus to the
ability score controlling his or her arcane spell-casting ability and
experience points sufficient to place him or her halfway into the next
level of experience. (If the reader has levels in more than one arcane
spellcasting class, he or she must choose one of the classes to be
affected.) Nonevil arcane spellcasters (LN, N, CN, LG, NG, or CG) are
permanently drained of 1d4+1 points of Constitution and must atone (see
the atonement spell) in order to gain any further experience. Anyone
incapable of casting arcane spells who reads even a single word of the
work must make a Will save (DC 20) or suffer insanity (see the insanity
spell). Except as indicated above, the writing in a libram of ineffable
damnation can&#8217;t be distinguished from any other book, libram, tome, or
so on until perused. Once read, the book vanishes, never to be seen
again, nor can the same character ever benefit from reading a similar
tome a second time.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 3 lb. </p>
    <a id="libram-of-silver-magic"></a><p><span style={{
        "fontWeight": "bold"
      }}>Libram of Silver Magic:</span>
This mystic book is of great value to arcane spellcasters of neutral
alignment (LN, N, CN). Study of the work requires one week. Upon
completion, the neutral arcane caster gains a +1 inherent bonus to the
ability score controlling his or her arcane spellcasting ability and
experience points sufficient to place him or her halfway into the next
level of experience. (If the reader has levels in more than one arcane
spellcasting class, he or she must choose one of the classes to be
affected.) Evil or good arcane spellcasters (LE, NE, CE, LG, NG, or CG)
are permanently drained of 1d4+1 points of Constitution and must atone
(see the atonement spell) in order to gain any further experience.
Anyone incapable of casting arcane spells who reads even a single word
of the work must make a Will save (DC 20) or suffer insanity (see the
insanity spell). Except as indicated above, the writing in a libram of
silver magic can&#8217;t be distinguished from any other book, libram, tome,
or so on until perused. Once read, the book vanishes, never to be seen
again, nor can the same character ever benefit from reading a similar
tome a second time.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 3 lb. </p>
    <a id="manual-of-puissant-skill-at-arms"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manual of Puissant Skill at
Arms:</span> This treatise contains expert advice and instruction in
the arts of combat. Any barbarian, fighter, monk, paladin, or ranger
who spends one week studying the manual gains a +1 inherent bonus to
Strength and experience points sufficient to place him or her halfway
into the next level of experience. (If the reader has levels in more
than one of the classes listed, he or she must choose one of the
classes to be affected.) A character without any levels in one of the
classes listed gains no bonus from the work, but if an arcane
spellcaster without levels in one of the classes listed scans even a
single word he or she will lose 2dx1,000 XP and must make a Will save
(DC 20) or have 1 point of Intelligence permanently drained. Except as
indicated above, the writing in a manual of puissant skill at arms
can&#8217;t be distinguished from any other book, libram, tome, or so on
until perused. Once read, the book vanishes, never to be seen again,
nor can the same character ever benefit from reading a similar tome a
second time.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 3 lb. </p>
    <a id="manual-of-stealthy-pilfering"></a><p><span style={{
        "fontWeight": "bold"
      }}>Manual of Stealthy Pilfering:</span>
This guide to thievery grants any rogue who spends a week studying its
lessons a +1 inherent bonus to Dexterity and experience points
sufficient to place him or her halfway into the next level of
experience. A character without any rogue levels gains no bonus from
the work, but if a divine spellcaster without rogue levels scans even a
single word she will lose 2dx1,000 XP and must make a Will save (DC 20)
or have 1 point of Wisdom permanently drained. Except as indicated
above, the writing in a manual of stealthy pilfering can&#8217;t be
distinguished from any other book, libram, tome, or so on until
perused. Once read, the book vanishes, never to be seen again, nor can
the same character ever benefit from reading a similar tome a second
time.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 3 lb. </p>
    <a id="rogues-dice"></a><p><span style={{
        "fontWeight": "bold"
      }}>Rogue&#8217;s Dice:</span> This
pair of cubes appears much like any other pair of typical (6-sided)
dice. A character with a pair of rogue&#8217;s dice who wishes to roll them
must announce that he or she is rolling the dice (accidental rolls have
no effect). Rolling the dice is a standard action, and both dice must
be rolled simultaneously to have any effect. The player should roll 2d6
and consult the table below to determine the dice&#8217;s affects.
      <a id="table-rogues-dice"></a></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>2d6
          </th>
          <th>Effect
          </th>
        </tr>
        <tr className="odd-row">
          <td>2
          </td>
          <td>Lose 10,000 XP and dice roll again next round
          </td>
        </tr>
        <tr>
          <td>3
          </td>
          <td> Permanently lose 1d4+1 Dex</td>
        </tr>
        <tr className="odd-row">
          <td>4
          </td>
          <td>Gain 1d4 negative levels (Fortitude DC 20 to remove)</td>
        </tr>
        <tr>
          <td>5
          </td>
          <td>-1 penalty on all attacks, saves, and checks for 1 hour</td>
        </tr>
        <tr className="odd-row">
          <td>6
          </td>
          <td>-4 penalty to AC for 10 minutes</td>
        </tr>
        <tr>
          <td>7
          </td>
          <td>+1 morale bonus on attacks and on saves against fear
for 10 minutes</td>
        </tr>
        <tr className="odd-row">
          <td>8
          </td>
          <td>Gain effects of blur for 10 minutes</td>
        </tr>
        <tr>
          <td>9
          </td>
          <td>+1 insight bonus on all attacks, saves, and checks for
1 hour</td>
        </tr>
        <tr className="odd-row">
          <td>10
          </td>
          <td>Gain effects of freedom of movement for 1 hour</td>
        </tr>
        <tr>
          <td>11
          </td>
          <td>Gain one limited wish (must be used within 1 minute)</td>
        </tr>
        <tr className="odd-row">
          <td>12
          </td>
          <td>Gain 10,000 XP and may roll again next round </td>
        </tr>
      </tbody>
    </table>
    <p>No character can gain any effect from an additional roll of
the dice within 24 hours, with two exceptions. If a 2 is rolled, the
dice automatically roll themselves at the beginning of the character&#8217;s
next turn and he or she must accept the additional result. If a 12 is
rolled, the character may choose to roll again in the next round (if
more than 1 full round elapses between the roll of 12 and this bonus
roll, the character loses the bonus roll). There is no method (mundane
or magical) to predict or influence the result of a roll of Rogue&#8217;s
dice. Even powerful divination magic can&#8217;t predict the outcome of a
roll before it is made.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> -.
    </p>
    <a id="major-artifacts"></a><h4>MAJOR ARTIFACTS </h4>
    <a id="axe-of-the-dwarvish-lords"></a><p><span style={{
        "fontWeight": "bold"
      }}>Axe of the Dwarvish Lords:</span>
This is a +6 keen throwing goblinoid bane dwarven waraxe. Any dwarf who
holds it doubles the range of his or her darkvision. Any nondwarf who
grasps the Axe takes 2 points of temporary Charisma damage; these
points cannot be healed or restored in any way while the Axe is held.
The current owner of the Axe gains a +10 bonus on Craft (armorsmithing,
blacksmithing, gemcutting, stonemasonry, and weaponsmithing) checks.
The wielder of the Axe can summon an elder earth elemental (as summon
monster IX; duration 20 rounds) once per week. </p>
    <a id="codex-of-the-infinite-planes"></a><p><span style={{
        "fontWeight": "bold"
      }}>Codex of the Infinite Planes:</span>
The Codex is enormous-supposedly, it requires two strong men to lift
it. No matter how many pages are turned, another always remains. Anyone
opening the Codex for the first time is utterly annihilated, as with a
destruction spell (Fortitude DC 30 for only 10d6 damage). Those who
survive can peruse its pages and learn its powers, though not without
risk. Each day spent studying the Codex allows the reader to make a
Spellcraft check (DC 50) to learn one of its powers (choose the power
learned randomly; add a +1 circumstance bonus on the check per
additional day spent reading until a power is learned). However, each
day of study also forces the reader to make a Will save (DC 30 + 1 per
day of study) to avoid being driven insane (as the insanity spell). The
powers of the Codex of the Infinite Planes are as follows: astral
projection, banishment, elemental swarm, gate, greater planar ally,
greater planar binding, plane shift, and soul bind. Each is usable at
will by the owner of the Codex (assuming that he or she has learned how
to access the power). The Codex of the Infinite Planes has a caster
level of 30th for the purposes of all powers and catastrophes, and all
saving throw DCs are 20 + spell level. Activating any power requires
both a Concentration check and a Spellcraft check (DC 40 + twice the
spell level of the power; the character can&#8217;t take 10 on this check).
Any failure on either check indicates a catastrophe befalls the user
(roll on the table below for the effect). A character can only incur
one catastrophe per power use, even if he or she fails both checks.
      <a id="table-codex-of-infinite-planes-catastophes"></a></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>d%
          </th>
          <th>Catastophe
          </th>
        </tr>
        <tr className="odd-row">
          <td>01-25
          </td>
          <td>Natural Fury: An earthquake spell centered on the
reader strikes every round for 1 minute, and an intensified storm of
vengeance spell is centered and targeted on the reader.
          </td>
        </tr>
        <tr>
          <td>25-50
          </td>
          <td>Fiendish Vengeance: A gate opens and 1d3+1 balor
demons, pit fiends, or
similar evil outsiders immediately step through and attempt to destroy
the owner of the Codex.</td>
        </tr>
        <tr className="odd-row">
          <td>51-75
          </td>
          <td>Ultimate Imprisonment: Reader&#8217;s soul is captured (as
trap the soul; no
save allowed) in a random gem somewhere on the plane while his or her
body is entombed beneath the earth (as imprisonment).</td>
        </tr>
        <tr>
          <td>76-100
          </td>
          <td>Death: The reader utters a wail of the banshee and then
is subject to a
destruction spell. This repeats every round for 10 rounds until the
reader is dead.</td>
        </tr>
      </tbody>
    </table>
    <a id="cup-and-talisman-of-the-demigod"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cup and Talisman of the
Demigod:</span> The Cup is a large gem-set golden chalice requiring two
hands to lift. It emits light (as the daylight spell) at all times and
automatically dispels any darkness-based spells whose area it enters.
If the cup is filled with holy water (requiring a full gallon), that
substance will act as a potion of cure critical wounds or a potion of
neutralize poison (owner&#8217;s choice) if drunk. This liquid can&#8217;t be saved
or stored in any way. The Talisman is a small eight-pointed platinum
star hanging from a chain of gold and pearls. The wearer gains a +6
enhancement bonus to Charisma and may cast remove blindness/deafness,
remove curse, and remove disease at will. Furthermore, if the Talisman
is placed within the Cup and the Cup filled with holy water, that
liquid acts as a special elixir of resurrection (as the spell). This
effect will function only once per month. Any evil or chaotic creature
that touches the Cup or Talisman is struck with a holy word (if evil)
or dictum (if chaotic) spell (or both if the creature is chaotic evil).
    </p>
    <a id="eye-of-the-orc"></a><p><span style={{
        "fontWeight": "bold"
      }}>Eye of the Orc:</span> This
lump of rock has a marquis-cut black sapphire set in its center, making
it appear similar to a large eye. If possessed by an orc, it grants a
+6 enhancement bonus to Strength and Charisma, and the orc&#8217;s darkvision
range is doubled. Nonorcs who possess the Eye gain a +2 enhancement
bonus to Strength but take a -2 penalty to Intelligence and Charisma.
Regardless of the owner&#8217;s race, any weapon wielded by the owner of the
Eye is considered an elf bane weapon. Furthermore, by holding the Eye
before the character, he or she can perceive things as if he or she had
true seeing cast upon him or her. This can be activated only once per
day but lasts as long as the character concentrates on it (requiring a
standard action each round). </p>
    <a id="golem-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Golem Armor:</span> This
enormous suit of black iron +10 full plate armor increases the wearer&#8217;s
size by one category (to a maximum of Colossal). The wearer gains a +10
enhancement bonus to Strength and is rendered immune to mind-affecting
effects, poison, disease, and similar effects. He or she is not subject
to critical hits, subdual damage, ability damage, energy drain, or
death from massive damage. Golem Armor is immune to rust attacks. The
wearer of Golem Armor gains damage reduction 15/adamantine. He or she
also cannot regain hit points by any means (mundane or magical) while
the armor is worn. It requires 1 hour to don or extract oneself from
Golem Armor. </p>
    <a id="invulnerable-coat"></a><p><span style={{
        "fontWeight": "bold"
      }}>Invulnerable Coat:</span> The
Invulnerable Coat +5 chain shirt of heavy fortification. It grants its
wearer damage reduction 10/epic and resistance 20 against acid, cold,
electricity, fire, and sonic energy. If the wearer can turn undead,
treat the wearer as having +4 class levels for purposes of caster
level, turning undead, smiting evil, and laying on hands. </p>
    <a id="iron-gauntlet-of-war"></a><p><span style={{
        "fontWeight": "bold"
      }}>Iron Gauntlet of War:</span>
The Gauntlet grants a +8 enhancement bonus to Strength. The wearer
doubles the damage bonus on any smite attacks he or she makes. If the
wearer has the Leadership feat, the wearer&#8217;s Leadership score increases
by +4, but the wearer can never attract or keep followers or cohorts
who are good or chaotic. Once per day, the wielder can use implosion as
a 20th-level caster (DC 23). The Iron Gauntlet of War is intelligent
(Int 13, Wis 18, Cha 24, Ego 26) and lawful evil. It can communicate
telepathically with its wearer, though it cannot speak. It will always
seek to dominate any wearer who isn&#8217;t lawful evil, forcing such an
owner to commit lawful evil deeds (or else find a more suitable
wearer). </p>
    <a id="ring-of-nine-facets"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ring of Nine Facets:</span>
This ring is set with a gem of nine facets, each one in-scribed with a
different incomprehensible rune. Each day at sunrise, the gem turns to
display a different facet. The active facet determines the Ring&#8217;s power
for that day. Each day, roll 1d10 to determine which facet (and thus
which power) is active.
    </p>
    <a id="table-ring-of-nine-facets"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>1d10
          </th>
          <th>Active Facet Power
          </th>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td>Wearer is immune to disease.
          </td>
        </tr>
        <tr>
          <td>2
          </td>
          <td>Wearer needs no air to survive</td>
        </tr>
        <tr className="odd-row">
          <td>3
          </td>
          <td>Wearer gains +5 natural armor bonus</td>
        </tr>
        <tr>
          <td>4
          </td>
          <td>Wearer gains low-light vision</td>
        </tr>
        <tr className="odd-row">
          <td>5
          </td>
          <td>Wearer gains fast healing 1</td>
        </tr>
        <tr>
          <td>6
          </td>
          <td>Wearer can fly at will &nbsp;&nbsp;&nbsp; </td>
        </tr>
        <tr className="odd-row">
          <td>7
          </td>
          <td>Wearer gains cold resistance 30</td>
        </tr>
        <tr>
          <td>8
          </td>
          <td> Wearer gains freedom of movement</td>
        </tr>
        <tr className="odd-row">
          <td>9
          </td>
          <td>Wearer gains +5 resistance bonus on saving throws</td>
        </tr>
        <tr>
          <td>10
          </td>
          <td>Wearer may select which facet is active</td>
        </tr>
      </tbody>
    </table>
    <p>The wearer of the Ring can, with a great deal of mental
exertion, attempt to change the active facet away from one he or she
does not desire. This requires a full-round action and a Concentration
check (DC 50), and deals 2d6 points of nonlethal damage regardless of
success. If the save is successful, the new facet is determined
randomly. If the Ring is removed, the former wearer takes 2d6 points of
nonlethal damage each minute until it is replaced or until his or her
nonlethal damage exceeds his or her current hit points (though the
nonlethal damage resumes again immediately when the former wearer
regains consciousness).
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      